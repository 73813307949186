.modal-default {
  width: 80%;
  max-width: 800px;
}

.modal-big {
  width: 90%;
  max-width: 1200px;
}

@media (min-width: 640px) {
  .modal-default {
    width: 90%;
  }
}

@media (min-width: 768px) {
  .modal-default {
    width: 80%;
  }
}

@media (min-width: 1024px) {
  .modal-default {
    width: 70%;
  }
}
