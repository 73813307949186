.chat-container {
  display: flex;
  flex-direction: column;
  margin: 70px auto 20px auto;
  padding: 0 20px;
  max-width: 900px;
  height: calc(100% - 70px - 20px);

  @media (max-width: 767px) {
    padding: 0 10px;
  }
}

.feedback-btn {
  padding: 10px 20px;
  font-size: 16px;
  background-color: #0071f3b9;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}
